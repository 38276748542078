<script setup lang="ts">
import { NuxtError } from 'nuxt/app';
import { RouteLocationRaw } from 'vue-router';
import { ApiException } from '~/codegen/axos-client';

const error = ref<any>();

async function clearMyError(options?: { redirect?: RouteLocationRaw }) {
	error.value = null;
	clearError();
}

const route = useRoute();
watch(
	() => route.fullPath,
	() => {
		error.value = null;
	}
);

interface APIV2Error {
	code?: string;
	message: string;
	resourceId?: string;
}

const { IS_DEV } = useEnvironment();

onErrorCaptured((capturedError: NuxtError | ApiException) => {
	// Do not show toasts in development
	if (IS_DEV) {
		return true;
	}

	// Ignore error from vue-router, this only happens in development
	// https://github.com/vuejs/router/issues/845
	if (capturedError.message.includes('Missing required param')) {
		return false;
	}

	if (capturedError?.response) {
		/* const errorData: APIV2Error = JSON.parse(capturedError.response);
		$error(errorData?.resourceId ?? errorData.message); */
		return false;
	}

	// Show toast on non-fatal errors
	if (!capturedError.fatal) {
		console.error(capturedError);
		$error(capturedError);
		return false;
	}

	error.value = capturedError;
	console.error(capturedError);
	return false;
});
</script>

<template>
	<slot v-if="!error" />

	<slot
		v-if="error"
		name="error"
		:error="error"
		:clear-error="clearMyError"
	/>
</template>
